import round from "lodash/round";

export const DEFAULT_YEAR = "2025";

const BASE_MULTIPLIER = {
  2021: 0.004,
  2022: 0.006,
  2023: 0.008,
  2024: 0.0074,
  2025: 0.0092,
};
const WA_CARES_MULTIPLIER = {
  2023: 0.0058,
  2024: 0.0058,
  2025: 0.0058,
};
const EMPLOYEE_SHARE_FAMILY = {
  2021: 0.3333,
  2022: 0.5131,
  2023: 0.5048,
  2024: 0.4805,
  2025: 0.4822,
};
const EMPLOYEE_SHARE_MEDICAL = {
  2021: 0.3,
  2022: 0.2191,
  2023: 0.2228,
  2024: 0.2338,
  2025: 0.233,
};

function grossToBase(value, yearInt) {
  return value * BASE_MULTIPLIER[yearInt];
}

export function baseToEmployeeShare(value, type, year = DEFAULT_YEAR) {
  const yearInt = parseInt(year, 10);
  const share =
    type === "family"
      ? EMPLOYEE_SHARE_FAMILY[yearInt]
      : EMPLOYEE_SHARE_MEDICAL[yearInt];
  return round(grossToBase(value, yearInt) * share, 2);
}

export function getEmployerMedicalShare(value, year = DEFAULT_YEAR) {
  const yearInt = parseInt(year, 10);
  const base = round(grossToBase(value, yearInt), 2);
  const employeeContribution =
    baseToEmployeeShare(value, "family", year) +
    baseToEmployeeShare(value, "medical", year);
  return base - employeeContribution;
}

export function getEmployeeWaCaresShare(value, year = DEFAULT_YEAR) {
  const yearInt = parseInt(year, 10);
  const multiplier = WA_CARES_MULTIPLIER[yearInt];
  if (!multiplier) return "N/A";
  return round(value * multiplier, 2);
}

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
});
