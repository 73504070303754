import React from "react";

export default {
  label: {
    back: `Back`,
    next: `Next`,
    yes: "Yes",
    no: "No",
    close: "Close",
    past: "Already happened",
    future: "Happening in the future",
    start: "Start here",
    restart: "Start over",
    applyNow: "Apply now",
    applyFuture: "Apply now",
    learn: "Learn more",
  },
  intro: {
    heading: `Before you apply for Paid Family and Medical Leave,
      it’s important to see if you meet the requirements to take leave.`,
    body: `Answer a few quick questions to see
      if now is the right time to apply.`,
  },
  qWork: {
    heading: `Did you work in Washington state in the last 18 months?`,
    dqMessage: `Sorry, but Paid Family and Medical leave is only available 
      to people who worked in Washington in the last 18 months. It looks 
      like you may not qualify for paid leave.`,
  },
  qEvent: {
    heading: `Did you recently experience one of the following qualifying
      events? Or do you know that one of them will happen soon?`,
    accordions: [
      {
        heading: `A serious health condition or injury`,
        body: (
          <p>
            An illness, injury, or physical or mental health condition that
            keeps you from working. (Major surgeries, illnesses like cancer and
            inpatient treatment for substance abuse are examples of serious
            health conditions.)
          </p>
        ),
      },
      {
        heading: `Welcoming a new child into your family`,
        body: (
          <p>
            This includes a baby being born into your family, adopting a child
            or having a foster child placed with your family.
          </p>
        ),
      },
      {
        heading: `A family member’s overseas military deployment or return
          from overseas deployment`,
        body: (
          <p>
            If you have a family member in the military who is about to be
            deployed overseas or is returning home from overseas deployment for
            R&R, you can qualify for paid leave to spend time with them.
          </p>
        ),
      },
    ],
  },
  qFamily: {
    heading: `Does your family member have a serious health condition or
      injury? Or do they have a medical event like a major surgery coming
      up?`,
    dqMessage: `Paid Family and Medical Leave is here for you when you need
      time off to care for yourself or a family member, to bond with a new child
      or spend time with a family member preparing for military service
      overseas. It looks like you may not qualify for paid leave.`,
    accordions: [
      {
        heading: `Family member`,
        body: (
          <>
            <p>
              These are the family members you can take paid leave to care for:
            </p>
            <ul>
              <li>Spouses and domestic partners</li>
              <li>Children (biological, adopted, foster or stepchild)</li>
              <li>Parents and legal guardians (and spouse’s parents)</li>
              <li>Siblings</li>
              <li>Grandchildren</li>
              <li>Grandparents (and spouse’s grandparents)</li>
              <li>
                Someone who has an expectation to rely on you for care—whether
                you live together or not.
              </li>
            </ul>
          </>
        ),
      },
      {
        heading: `Serious health condition`,
        body: (
          <p>
            An illness, injury, or physical or mental health condition that
            keeps a person from working. (Major surgeries, illnesses like
            cancer, and inpatient treatment for substance abuse are examples of
            serious health conditions.)
          </p>
        ),
      },
    ],
  },
  qApply: {
    heading: `Does one of the following apply to you?`,
    body: (
      <>
        <p>I did not work for any other Washington employer and:</p>
        <ul>
          <li>I worked for a federal employer in the last 18 months</li>
          <li>
            My employer provides paid family and medical leave benefits through
            an{" "}
            <a
              href="/voluntary-plans/"
              target="_blank"
              rel="noreferrer noopener"
            >
              approved voluntary plan
            </a>
          </li>
          <li>
            I am self-employed and have <em>not</em> opted in to Paid Family and
            Medical Leave
          </li>
          <li>
            I work for a federally recognized tribe or a business that is owned
            by the government of a federally recognized tribe, and that business
            has not opted in to the state plan
          </li>
          <li>
            I work for a business that is on tribal land and owned by a member
            of a federally recognized tribe
          </li>
        </ul>
      </>
    ),
    accordions: [
      {
        heading: `A federally recognized tribe or a business that is owned by
          the government of a federally recognized tribe`,
        body: (
          <p>
            Tribal governments need to opt in for their employees and for
            employees of businesses owned by tribal governments to receive paid
            leave.
          </p>
        ),
      },
      {
        heading: `A business that is on tribal land and owned by a member of
          a federally recognized tribe`,
        body: (
          <p>
            Employees of these businesses are not eligible for Paid Family and
            Medical Leave through the state plan.
          </p>
        ),
      },
    ],
    dqMessage: `Sorry, but it looks like you may work for an employer that
      isn’t covered by Paid Family and Medical Leave.`,
  },
  qHours: {
    heading: `Did you work at least 820 hours in the qualifying period? (The 820 hours can be for one job or multiple jobs.)`, // eslint-disable-line max-len
    body: (
      <>
        <p>
          Learn more about the{" "}
          <a
            href="/question/qualifying-period/"
            target="_blank"
            rel="noreferrer noopener"
          >
            qualifying period
          </a>
          .
        </p>
        <p>
          If you are self-employed, have opted in to Paid Family and Medical
          Leave and earned at least $11,070 in the last year, choose Yes.
        </p>
      </>
    ),
    dqMessage: `Sorry, but it looks like you may not have worked enough hours 
      to qualify for Paid Family and Medical Leave. However, if your hours were 
      impacted by COVID-19, you might still qualify for leave.`,
  },
  qEventTime: {
    heading: `Did you or your family member’s qualifying event happen already?
      Or will it happen sometime in the future?`,
    accordions: [
      {
        heading: `Qualifying Event`,
        body: (
          <>
            <p>
              You can receive paid leave if you or a family member experience a
              “qualifying event.”
            </p>
            <p>Here are some examples of qualifying events:</p>
            <ul>
              <li>
                You welcome a baby into your family, adopt a child or have a
                foster child placed with your family.
              </li>
              <li>You are put on bed rest during pregnancy and can’t work.</li>
              <li>
                You experience a major surgery, serious illness or injury.
              </li>
              <li>
                You are receiving treatment for a chronic health condition like
                diabetes or epilepsy.
              </li>
              <li>
                You are receiving inpatient treatment for substance abuse or for
                mental health.
              </li>
              <li>
                You are taking care of a family member with a serious health
                condition or injury.
              </li>
              <li>
                Your family member is on active duty military service, and you
                take time to be with them during R&R.
              </li>
            </ul>
          </>
        ),
      },
    ],
    message: {
      true: `Based on your responses, you may qualify for Paid Family and
        Medical Leave. Review the <a href="/benefit-guide/">Benefit Guide</a>
        to make sure you know what you need to apply for and take leave, then
        get started applying.`,
      false: `Based on your responses, you may qualify for Paid Family and
        Medical Leave in the future. Make sure you
        <a href="/get-ready-to-apply/">have what you need to apply</a>. Then get
        started applying.`,
    },
  },
};
